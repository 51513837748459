
import { mapMutations, mapState } from 'vuex';
export default {
  name: 'order-page',
  props: {
    blok: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapState(['location'])
  },
  methods: {
    ...mapMutations(['setMenu'])
  },
  beforeMount() {
    this.setMenu(null) // Clear menu just in case
    const path = this.location?.address && this.location.menu_id
      ? `/menu/${this.location.menu_id}`
      : `/locations`
    this.$nuxt.$router.push(path);
  }
}
